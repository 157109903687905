import {
  Button,
  Checkbox,
  Form,
  Input,
  Popconfirm,
  Table,
  Typography,
} from 'antd';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';

const columns = [
  {
    title: 'SI. No.',
    dataIndex: 'index',
    width: 100,
    render: (_, record, i) => {
      return <Form.Item>{record?.name + 1}</Form.Item>;
    },
  },
  {
    title: 'Document Name *',
    dataIndex: 'documentName',
    render: (_, record, i) => {
      return (
        <Form.Item
          name={[record.name, 'documentName']}
          rules={[{ required: true, message: 'Enter Document Name' }]}>
          <Input />
        </Form.Item>
      );
    },
  },
  {
    title: 'Mandatory',
    dataIndex: 'isRequired',
    render: (_, record, i) => {
      return (
        <Form.Item name={[record.name, 'isRequired']} valuePropName='checked'>
          <Checkbox />
        </Form.Item>
      );
    },
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: (_, record, i) => {
      return (
        <Form.Item>
          <Popconfirm
            title='Delete'
            onConfirm={() => record.remove(record.name)}>
            <Typography.Link>
              <DeleteFilled />
              Delete
            </Typography.Link>
          </Popconfirm>
        </Form.Item>
      );
    },
  },
];

export default function DocumentFormTable({ field, disabled, type }) {
  const getName = (type) => {
    switch (type) {
      case 'centralIR':
        return 'Contract Workmen';

      case 'regularIR':
        return 'Regular IR';

      case 'contractAgency':
        return 'Contract Agency';

      default:
        return '';
    }
  };

  const renderFooter = (handleAdd) => {
    return (
      <Button
        type='dashed'
        htmlType='button'
        onClick={() => handleAdd({ isRequired: false })}
        block
        icon={<PlusOutlined />}
        disabled={disabled}>
        Add Documents
      </Button>
    );
  };
  return (
    <Form.List name={[field.name, `${type}Docs`]}>
      {(fields, actions) => {
        return (
          <Table
            title={() => (
              <Typography.Title level={5}>
                Documents for {getName(type)}
              </Typography.Title>
            )}
            columns={columns}
            dataSource={fields.map((f) => ({ ...f, remove: actions.remove }))}
            footer={() => renderFooter(actions.add)}
            locale={{
              emptyText: 'No Documents',
            }}
          />
        );
      }}
    </Form.List>
  );
}
