import React, { useEffect, useRef, useState, useContext } from 'react';
import { Button, Space, Input, Form, Table, Typography } from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  EditFilled,
  InteractionOutlined,
} from '@ant-design/icons';
import {
  getContractorList,
  getPermissionList,
  supplierEsi,
} from '../services/auth';
import Highlighter from 'react-highlight-words';
import AddContractor from '../components/Contractor/AddContractor';
import ReplaceContractor from '../components/Contractor/replaceContractor';
import dayjs from 'dayjs';
import { Editcontractor } from '../services/auth';
import { AuthContext } from '../contexts/AuthProvider';
import useAuth from '../hooks/useAuth';

export default function ContractorList({
  initialValues,
  onNext,
  locationsData,
}) {
  const [sortedInfo, setSortedInfo] = React.useState({});
  const [, forceUpdate] = React.useState({});
  const [Supplier, setSupplier] = React.useState([]);
  const [, setloading] = React.useState(true);
  const [, setEditingKey] = React.useState('');
  // search icon
  const [searchText, setSearchText] = React.useState('');
  const [searchedColumn, setSearchedColumn] = React.useState('');
  const [adminFormOpen, setAdminFormOpen] = React.useState(false);
  const [replaceOpen, setReplaceOpen] = React.useState(false);
  const [selectedContractor, setSelectedContractor] = useState({});
  const { customDropdownValues } = useContext(AuthContext);
  const [contractorCreated, setContractorCreated] = useState(false);
  const [permissionList, setPermissionList] = useState({});
  const formRef = React.useRef();

  // const [data, setData] = React.useState(initialValues);

  const DEFAULT_ADMIN_FORM_VALUE = {
    id: '',
    name: '',
    maxContractors: '',
    status: '',
    // doe: "",
    // logoFile: "",
  };
  const [formInitialValue, setFormInitialValue] = React.useState(
    DEFAULT_ADMIN_FORM_VALUE
  );
  const [editIndex, setEditIndex] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);

  const searchInput = useRef(null);
  const { userConfig } = useAuth();
  const orgId = userConfig.orgId; //admin
  const role = userConfig.role;
  const roleName = userConfig.roleName;

  if (userConfig?.roleName) {
  }
  useEffect(() => {
    ContractorList();
    forceUpdate({});
    // PermissionList();
    console.log(initialValues, "initialValues")
  }, []);

  useEffect(() => {}, [customDropdownValues]);

  const ContractorList = async () => {
    getContractorList().then((res) => {
      setloading(false);
      setSupplier(
        res.data.suppliers.map((row) => ({
          _id: row._id,
          key: row.id,
          id: row.id,
          name: row.name,
          // status: row.status,
          status: row?.status[0].toUpperCase() + row.status?.slice(1),
          // doe: row.doe,
          doe: dayjs(row.doe).format('DD-MM-YYYY'),
          location: row.location,
          Edited: row?.Edited,
          jobOrderEdited: row.addJobDetails.forEach((jobDetail) => {}),
        }))
      );
    });
  };
  let filteredData = [];

  if (userConfig.location) {
    // If userConfig.location is present, filter by both userConfig.location and supplier location
    filteredData = Supplier.filter(
      (supplier) => userConfig.location === supplier.location
    );
    // return filteredData
  } else {
    filteredData = Supplier;
  }

  if (
    userConfig.role === 'admin' &&
    userConfig.roleName &&
    userConfig.roleName.selectedRoleType === 'Admin Role'
  ) {
    filteredData = Supplier.filter((supplier) => {
      return userConfig.location === supplier.location;
    });
  } else {
    // filteredData = Supplier;
  }

  if (userConfig.role == 'contractor') {
    filteredData = Supplier.filter((supplier) => {
      if(userConfig?.supplierId){
        return supplier.id == userConfig.supplierId;
      }
      else{
        return supplier.id == userConfig.userid;
      }
    });
  } else {
    // filteredData = Supplier;
  }

  if (userConfig.role == 'approver') {
    filteredData = Supplier.filter((supplier) => {
      return supplier.Edited == 'Yes' || supplier.status == 'Active';
    });
  } else {
    // filteredData = Supplier;
  }

  // const PermissionList = async () => {
  //   getPermissionList(orgId, roleName).then((res) => {
  //     setloading(false);

  //
  //     setPermissionList(res?.data?.permissions);
  //   });
  // };
  //

  // const addAdmin = (value, isEdit = false) => {
  //   if (isEdit) {
  //     setData((currData) => {
  //       return currData.map((d, index) => {
  //         if (index === editIndex) {
  //           return value;
  //         }

  //         return d;
  //       });
  //     });

  //     setEditIndex(null);
  //   } else {
  //     setData((currData) => [...currData, value]);
  //   }
  //   setAdminFormOpen(false);
  // };

  const edit = (record) => {
    setAdminFormOpen(true);
    setEditIndex(record.index);
    setIsEdit(true);
    //  setFormInitialValue(
    //   data.find((d) => d.id === record.id) || DEFAULT_ADMIN_FORM_VALUE
    // );
    //   Editcontractor({ id: record.id })
    //     .then((res) => {
    //
    //       const Response = res.data;
    //       let data = {
    //         key: Response.id,
    //         id: res.data.id,
    //         name: res.data.name,
    //         address1: res.data.address1,
    //         address2: res.data.address2,
    //         address3: res.data.address3,
    //         district: res.data.district,
    //         pincode: res.data.pincode,
    //         emailId: res.data.emailId,
    //         mobileNo: res.data.mobileNo,
    //         principalEmpCode: res.data.principalEmpCode,
    //         unitId: res.data.unitId,
    //         managerId: res.data.managerId,
    //         poolWorkmen: res.data.poolWorkmen,
    //         pfEstablishmentSubCode: res.data.pfEstablishmentSubCode,
    //         periodContractorStartDate: dayjs(
    //           new Date(res.data.periodContractorStartDate)
    //         ),
    //         periodContractorStartDate: dayjs(
    //           new Date(res.data.periodContractorEndDate)
    //         ),

    //         pfApplyDate: dayjs(new Date(res.data.pfApplyDate)),
    //         periodContractorStartDate: dayjs(
    //           new Date(res.data.periodContractorStartDate)
    //         ),
    //         avrageDeployement: res.data.avrageDeployement,
    //         periodContractorEndDate: dayjs(
    //           new Date(res.data.periodContractorEndDate)
    //         ),
    //         esiValidFrom: dayjs(new Date(res.data.esiValidFrom)),
    //         esiValidTo: dayjs(new Date(res.data.esiValidTo)),
    //         managerName: res.data.managerName,
    //         natureOfWork: res.data.natureOfWork,
    //         totalLicenceCoverage: res.data.totalLicenceCoverage,
    //         maxContractors: res.data.maxContractors,
    //         doe: dayjs(new Date(res.data.doe)),
    //         location: res.data.location,
    //         esicno: res.data.esicno,
    //         pfAccountNo: res.data.pfAccountNo,
    //         jobordernumber: res.data.jobordernumber,
    //         llf: dayjs(new Date(res.data.llf)),
    //         lln: res.data.lln,
    //         logoFile: res.data.logoFile,
    //         nowc: res.data.nowc,
    //         wcpn: res.data.wcpn,
    //         wcvf: dayjs(new Date(res.data.wcvf)),
    //         uploadDocument: res.data.uploadDocument,
    //         wcvt: dayjs(new Date(res.data.wcvt)),
    //       };

    //       setFormInitialValue(data);
    //     })
    //     .catch((err) => {});
    // };
    Editcontractor({ id: record.id })
      .then((res) => {
        const Response = res.data;
        Response.statusFromDate = res.data.statusFromDate
          ? dayjs(new Date(res.data.statusFromDate))
          : '';
        Response.statusToDate = res.data.statusToDate
          ? dayjs(new Date(res.data.statusToDate))
          : '';
        Response.location = res.data.location;

        let data = Response;

        setFormInitialValue(data);
      })
      .catch((err) => {});
  };

  const replaceContractor = (record) => {
    setSelectedContractor(record);
    setReplaceOpen(true);
  };

  const onFinish = (values) => {
    onNext(values);
    setContractorCreated(true);
  };
  const openAdminModal = () => {
    setIsEdit(false);
    setAdminFormOpen(true);
  };

  // pagination
  const cancel = () => {
    setEditingKey('');
  };
  // Handle Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}>
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, record) => record.name.startsWith(value),
    },
    {
      title: 'Contractor Code',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id'),
      filterMode: 'tree',
      filterSearch: true,
      // onFilter: (value, record) => record.id.startsWith(value),
      // width: '30%',
      // sorter: (a, b) => a.id.length - b.id.length,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      ellipsis: true,
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    // {
    //   title: 'Transaction Status',
    //   dataIndex: 'jobOrderEdited',
    //   key: 'jobOrderEdited',
    // },
    // {
    //   title: 'Next Task',
    //   dataIndex: 'Edited',
    //   render: (_, record, _id) => {
    //     return (
    //       <Space>
    //         <Typography>{record.Edited === 'Yes' ? 'IR' : 'NA'}</Typography>
    //       </Space>
    //     );
    //   },
    // },

    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record, _id) => {
        return (
          <Space>
            <Typography.Link
              disabled={
                userConfig.role === 'admin'
                  ? userConfig.roleName &&
                    !roleName?.permissions?.Contractors?.edit
                  : ''
              }
              onClick={() => edit(record)}>
              <EditFilled />
            </Typography.Link>
            {/* commented by Aditya for now, de-comment when needed */}
            {/* {userConfig.role !== 'contractor' &&
              userConfig.role !== 'approver' && (
                <Typography.Link
                  onClick={() => replaceContractor(record)}
                  disabled={
                    userConfig.role === 'admin'
                      ? userConfig.roleName &&
                        !roleName?.permissions?.Contractors?.edit
                      : ''
                  }>
                  <InteractionOutlined />
                </Typography.Link>
              )} */}
          </Space>
        );
      },
    },
  ];
  const onChange = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
  };

  return (
    <>
      {/* <h2>Contractor Agency Onboarding</h2> */}
      <Form
        name='orgOnboard'
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}
        ref={formRef}>
        {userConfig.role !== 'contractor' && userConfig.role !== 'approver' && (
          <Form.Item>
            <Button
              type='dashed'
              onClick={openAdminModal}
              block
              disabled={
                userConfig.roleName &&
                !roleName?.permissions?.Contractors?.create
              }
              icon={<PlusOutlined />}>
              Add Contractor Agency
            </Button>
          </Form.Item>
        )}

        <AddContractor
          open={adminFormOpen}
          onCancel={() => {
            setFormInitialValue({});
            setAdminFormOpen(false);
          }}
          initialValues={formInitialValue}
          // onOk={addAdmin}
          isEdit={isEdit}
          locationsData={locationsData}
        />
        {replaceOpen && (
          <ReplaceContractor
            open={replaceOpen}
            onCancel={() => setReplaceOpen(false)}
            initialValues={selectedContractor}
            contractorsList={Supplier}
          />
        )}

        <Table
          columns={columns}
          dataSource={filteredData}
          onChange={onChange}
          pagination={true}
        />
        <Form.Item style={{ textAlign: 'end' }}>
          <Button
            type='primary'
            htmlType='submit'
            hidden={userConfig.role == 'admin'}>
            Next
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
