import axios from 'axios';
import { getAppStore } from '../store';

import { setLoader } from '../actions';
import { decrypt, encrypt } from '../utils/crypto';
import { notification } from 'antd';
//New Build Issue @Viresh Bangargi 27July2024
const instance = axios.create({
  //  baseURL: 'http://localhost:3000/api',
  // baseURL : 'http://localhost:80/api',
  // baseURL: 'https://demo.wikiworks.in:443/api',
  baseURL: 'https://test.wikiworks.in:443/api',
  //baseURL: 'https://vyn.wikiworks.in:443/api',
  // baseURL: `${process.env.REACT_APP_API_URL}`,

  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

instance.defaults.withCredentials = true;

// Set maximum allowed response content length
instance.defaults.maxContentLength = 2000000; // Example value, adjust as needed

// Set maximum allowed request body length
instance.defaults.maxBodyLength = 2000000;
instance.interceptors.request.use(async (config) => {
  const { loggedInUser = {} } = await getAppStore();
  if (config.data && process.env.REACT_APP_ENABLE_ENCRYPTION === 'Y') {
    config.data = {
      payload: encrypt(JSON.stringify(config.data)),
    };
  }
  // setLoader(true);
  const token = sessionStorage.getItem('token');
  // config.headers['token'] = (await loggedInUser?.token) || '';
  config.headers['token'] = token;
  return config;
});

export const setUpAxiosInterCeptors = (navigate, setUser) => {
  instance.interceptors.response.use(
    async (res) => {
      const response = { ...res };

      if (process.env.REACT_APP_ENABLE_ENCRYPTION === 'Y' && res.data.payload) {
        response.data = await JSON.parse(decrypt(res.data.payload));
      }

      setLoader(false);
      return response;
    },
    (err) => {
      const { status } = err.response;
      setLoader(false);
      if (status === 401) {
        if (err.response.config.url !== '/user/info') {
          notification.error({ message: 'Logged Out! Unauthorized access' });
        }
        setUser({ isAuthenticated: false, userConfig: {} });
        // navigate("/login");
      }

      return Promise.reject(err);
    }
  );
};

export default instance;
