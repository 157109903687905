//Mallikarjun Code
import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Button,
  Input,
  Space,
  Typography,
  Divider,
  pagination,
  Badge,
  Avatar,
  Tooltip,
} from 'antd';
import { DownloadOutlined, EyeFilled, SearchOutlined } from '@ant-design/icons';
import {
  getEmployeeList,
  searchEmployee,
  getWorkmenLimit,
} from '../services/auth';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { resolveOnChange } from 'antd/es/input/Input';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { downloadEmployeeList } from '../services/auth';
import { setLoader } from '../actions';
import useAuth from '../hooks/useAuth';
const EmployeeList = () => {
  const [sortedInfo, setSortedInfo] = useState({});
  const [Employee, setEmployee] = useState([]);
  console.log('EMP', Employee);
  const [loading, setloading] = useState(true);
  const [activityChanged, setActivityChanged] = useState(false);
  // Search icon
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [employementType, setemployementType] = useState('Contractor');
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState(null);
  const searchInput = useRef(null);
  const { userConfig } = useAuth();

  const contractorId = userConfig?.supplierId;
  const orgId = userConfig?.orgId;
  //

  useEffect(() => {
    EmployeeList(pageSize, currentPage, employementType);
  }, [pageSize, currentPage]);

  const structureEmployee = (row) => {
    const designation = row?.jobDetails?.designation;

    return {
      key: row?._id,
      id: row?.id,
      _id: row?._id,
      firstName:
        row?.personalDetails?.firstName + ' ' + row.personalDetails?.lastName,
      aadharNo: row?.aadhar[0]?.maskedAadhaarNumber || '',

      village: row?.personalDetails?.village,
      address: row?.personalDetails?.address,
      state: row?.personalDetails?.state,
      district: row?.personalDetails?.district,
      pincode: row?.personalDetails?.pincode,
      mobile: row?.personalDetails?.mobile,
      fatherName: row?.personalDetails?.fatherName,
      gender: row?.personalDetails?.gender,
      Uan: row?.personalDetails?.uan,
      pfAccountNo: row?.personalDetails?.pfAccountNo,
      esicno: row?.personalDetails?.esicno,
      // empCode: row.personalDetails.empCode,
      //Job Details
      departmentCode: row?.jobDetails?.departmentCode,
      sectionCode: row?.jobDetails?.sectionCode,
      designation: row?.jobDetails?.designation,
      bloodGroup: row?.jobDetails?.bloodGroup,
      AccountNo: row?.jobDetails?.AccountNo,
      bankname: row?.jobDetails?.bankname,
      name: row?.jobDetails?.name,
      ifsc: row?.jobDetails?.ifsc,
      employeeType: row?.jobDetails?.employeeType,
      userName: row?.jobDetails?.userName,
      academicQualification: row?.jobDetails?.academicQualification,
      jobCode: row?.jobDetails?.jobCode,
      labourType: row?.jobDetails?.labourType,
      itemServiceCode: row?.jobDetails?.itemServiceCode,
      //Wage Details
      basic: row?.wageDetails?.basic,
      da: row?.wageDetails?.da,
      userName: row?.wageDetails?.userName,
      allowance1: row?.wageDetails?.allowance1,
      allowance2: row?.wageDetails?.allowance2,
      allowance3: row?.wageDetails?.allowance3,
      conveyance: row?.wageDetails?.conveyance,
      hra: row?.wageDetails?.hra,
      ot: row?.wageDetails?.ot,
      status: row.status
        ? row?.status[0]?.toUpperCase() + row?.status?.slice(1)
        : '',
      createdBy: row?.createdBy,
      locationId: row?.locationId,
      empCode: row?.personalDetails?.empCode,
      epnNumber: row?.personalDetails?.epnNumber,

      approvalStage: row?.approvalStage,

      erdate: dayjs(row?.erdate).format('DD-MM-YYYY'), // Format erdate
      medicaldate: dayjs(row?.medicaldate).format('DD-MM-YYYY'), // Format medicaldate
      safetydate: dayjs(row?.safetydate).format('DD-MM-YYYY'), // Format safetydate
      supervisordate: dayjs(row?.supervisordate).format('DD-MM-YYYY'), // Format supervisordate
      Edited: row?.Edited,
      medicalException: row?.exceptions?.Medical?.includes(designation),
      safetyException: row?.exceptions?.Safety?.includes(designation),
      medicalNeeded: row?.medicalNeeded,
      safetyNeeded: row?.safetyNeeded,
      policeVerificationDocUploaded: row?.policeVerificationDocUploaded,
      gatePassStage: row?.gatePassStage,
      contractorName: row?.jobDetails?.contractorName,
      workerType: row?.jobDetails?.designation,
    };
  };
  const workmenLimit = async () => {
    //
    getWorkmenLimit(orgId)
      .then((res) => {
        const jobOrderNumbers = [];
        const maxContractorsArray = [];

        res.data.forEach((item) => {
          if (item.addJobDetails && Array.isArray(item.addJobDetails)) {
            item.addJobDetails.forEach((details) => {
              jobOrderNumbers.push(details?.jobordernumber);
            });
          }

          if (item.addJobDetails && Array.isArray(item.addJobDetails)) {
            item.addJobDetails.forEach((details) => {
              maxContractorsArray.push(details?.maxContractors);
            });
          }

          const uniqueJobOrderNumbers = [...new Set(jobOrderNumbers)];

          // setContractorList(uniqueJobOrderNumbers);
          // setMaxContractors(maxContractorsArray);

          // setData(res.data);
          // setMaxContractorLimit(res.data.maxContractors)
          // setlln(res.data.lln)
        });
        // .catch((error) => {
        //   setLoader(false);
        //
        // });

        // if (item?.addJobDetails && Array.isArray(item?.addJobDetails)) {
        //   item.addJobDetails.forEach((details) => {
        //     maxContractorsArray.push(details?.maxContractors);
        //   });
        // }

        const uniqueJobOrderNumbers = [...new Set(jobOrderNumbers)];

        // setContractorList(uniqueJobOrderNumbers);
        // setMaxContractors(maxContractorsArray);

        //
        //
        //

        // setData(res.data);
        // setMaxContractorLimit(res.data.maxContractors)
        // setlln(res.data.lln)
      })
      .catch((error) => {
        setLoader(false);
      });
  };
  useEffect(() => {
    // Fetch data when the component mounts
    workmenLimit();
  }, [workmenLimit]);

  // const EmployeeList = async (pageSize, currentPage,employementType) => {
  //   setLoader(true);
  //   getEmployeeList(pageSize, currentPage,employementType)
  //     .then((res) => {
  //       setLoader(false);
  //       // setloading(false);
  //       setTotalCount(res?.data?.count);
  //       setEmployee(
  //         res?.data?.passList?.map((row) => {
  //           return structureEmployee(row);
  //         })
  //       );
  //     })
  //     .catch((error) => {
  //       setLoader(false);
  //     });
  // };
  //

  // const SearchEmployeeData = async (search) => {
  //   searchEmployee(pageSize, currentPage, search).then((res) => {
  //     setloading(false);
  //     setTotalCount(res?.data?.count);

  //     setEmployee(
  //       res.data.passList.map((row) => {
  //         return structureEmployee(row);
  //       })
  //     );
  //   });
  // };
  const EmployeeList = async (pageSize, currentPage, employementType) => {
    setLoader(true);
    try {
      const res = await getEmployeeList(pageSize, currentPage, employementType);
      setLoader(false);
      setTotalCount(res?.data?.count);
      setEmployee(res?.data?.passList?.map((row) => structureEmployee(row)));
    } catch (error) {
      setLoader(false);
    }
  };
  const SearchEmployeeData = async (search) => {
    try {
      const res = await searchEmployee(pageSize, currentPage, search);
      setloading(false);
      setTotalCount(res?.data?.count);
      setEmployee(res.data.passList.map((row) => structureEmployee(row)));
    } catch (error) {
      setloading(false);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Enter atleast 5 character`}
          value={selectedKeys[0]}
          onChange={(e) => {
            console.log('object946', e.target.value);
            if (e.target.value.length >= 5) {
              SearchEmployeeData(e.target.value);
            } else if (
              e.target.value.length === 0 ||
              e.target.value.length === 2
            ) {
              EmployeeList(pageSize, currentPage);
            }
            // setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Worker Name',
      dataIndex: 'firstName',
      key: 'firstName',
      ...getColumnSearchProps('firstName'),

      filterMode: 'tree',
      filterSearch: true,
      fixed: 'left', // Freeze this column

      sortOrder: sortedInfo.columnKey === 'firstName' ? sortedInfo.order : null,
      ellipsis: true,
      width: 150,
    },
    {
      title: 'Contractor Name',
      dataIndex: 'contractorName',
      key: 'aadharNo',
      fixed: 'left', // Freeze this column

      width: 150,
    },
    // {
    //   title: 'ID Number',
    //   dataIndex: 'contractorName',
    //   key: 'aadharNo',
    //   fixed: 'left', // Freeze this column

    //   width: 150,
    // },
    {
      title: 'Worker Code',
      dataIndex: 'empCode',
      key: 'empCode',
      ...getColumnSearchProps('empCode'),

      filterMode: 'tree',
      fixed: 'left', // Freeze this column

      filterSearch: true,
      sortOrder: sortedInfo.columnKey === 'empCode' ? sortedInfo.order : null,
      ellipsis: true,
      width: 150,
    },
    {
      title: 'EPN Number',
      dataIndex: 'epnNumber',
      key: 'epnNumber',

      ...getColumnSearchProps('epnNumber'),

      filterMode: 'tree',
      fixed: 'left', // Freeze this column

      filterSearch: true,
      sortOrder: sortedInfo.columnKey === 'epnNumber' ? sortedInfo.order : null,
      ellipsis: true,
      width: 150,
    },

    {
      title: 'Aadhaar No',
      dataIndex: 'aadharNo',
      key: 'aadharNo',

      width: 150,
    },
    {
      title: 'Worker Type',
      dataIndex: 'workerType',
      key: 'workerType',

      width: 150,
    },

    //Naveen Code
    {
      title: 'Status',
      dataIndex: 'status',

      key: 'status',
      ...getColumnSearchProps('status'),
      render: (status) => {
        let colorClass;
        let displayStatus = status;
        switch (status) {
          case 'Approved':
            colorClass = 'active';

            displayStatus = 'Active';
            break;
          case 'Success':
            colorClass = 'success';
            break;
          case 'Pending':
            colorClass = 'pending';
            break;
          case 'Rejected':
            colorClass = 'reject';
            break;
          default:
            colorClass = '';
        }
        return <span className={colorClass}>{displayStatus}</span>;
      },
      filterMode: 'tree',
      filterSearch: true,
      // width: '17%',
      sortOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
      ellipsis: true,
      width: 150,
    },
    {
      // title: 'Pending Task',
      title: 'Transaction Status',
      dataIndex: 'approvalStage',
      key: 'approvalStage',

      render: (stage, record) => {
        if (stage === 0) {
          return (
            <>
              IR <br />
              {!record?.safetyException && 'Safety'}
              <br />
              {!record?.medicalException && 'Medical'}
            </>
          );
        } else if (stage === 1) {
          return (
            <>
              {!record?.safetyException && 'Safety'}
              <br />
              {!record?.medicalException && 'Medical'}
            </>
          );
        } else if (stage === 2 && !record?.medicalException) {
          // return 'Safety';
          return 'Medical';
        } else {
          // const employeeWithEditedFlag = Employee.find(
          //   (employee) => employee._id === record.key
          // );

          // if (
          //   employeeWithEditedFlag &&
          //   employeeWithEditedFlag.Edited === 'Yes'
          // ) {
          if (record.medicalNeeded || record.safetyNeeded) {
            //not equal to 0
            return (
              <>
                {(record.safetyNeeded == 1 || record.medicalNeeded == 1) && (
                  <>
                    IR <br />
                  </>
                )}

                {[1, 2].includes(record.safetyNeeded) && (
                  <>
                    Safety <br />
                  </>
                )}

                {[1, 2].includes(record.medicalNeeded) && 'Medical'}
              </>
            );
          } else if (
            record.gatePassStage != undefined &&
            record.gatePassStage < 2
          ) {
            if (record.gatePassStage == 0)
              return 'Temporary GP to be generated';
            if (record.policeVerificationDocUploaded)
              return 'Permanent GP approval pending';
            if (record.gatePassStage == 1) return 'Temporary GP generated';
          } else if (record.Edited == 'Yes') {
            return 'Profile updated. Approval pending with IR.';
          } else {
            return 'Approved & Synched With WFD';
          }
        }
      },
      width: 150,
    },

    {
      title: 'Last Action Date',
      dataIndex: 'approvalStage',
      key: 'approvalStage',

      render: (stage, record) => {
        if (stage === 0) {
          return <>{record.supervisordate}</>;
        } else if (stage === 1) {
          return <>{record.erdate}</>;
        } else if (stage === 2) {
          return <> {record.medicaldate}</>;
        } else {
          return <>{record.safetydate}</>;
        }
      },
      width: 150,
    },

    {
      title: 'Location Id',
      dataIndex: 'locationId',
      key: 'locationId',

      width: 150,
    },

    {
      title: 'Actions',
      dataIndex: '_id',

      render: (id) => (
        <Typography>
          <Link
            to={`/home/supervisor/employee/${id}`}
            disabled={activityChanged ? true : false}>
            <EyeFilled />
          </Link>
        </Typography>
      ),
      width: 150,
    },
  ];
  const onChange = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
  };

  //Download Excel Sheet
  const createExcelSheet = (employeeData) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Employee List');

    // Add headers
    worksheet.addRow([
      'Emp Code',
      'First Name',
      'Last Name',
      'Aadhar Number',
      'Mobile Number',
      'Father Name',
      'Address ',
      'Village',
      'District',
      'State',
      'Pincode',
      'Pf Account Number',
      'ESI',
      'UAN',
      'Location Id',
      'Department Code',
      'Contractor Code',
      'Section Code',
      'Academic Qualification',
      'Designation',
      'Job Code',
      'Labour Type',
      'Item Service Code',
      'Blood Group',
      'Bank Name',
      'Account Holder Name',
      'Ifsc Code',
      'Account Number',
      'Basic',
      'DA',
      'Allowance1',
      'Allowance2',
      'Allowance3',
      'Conveyance',
      'HRA',
      'OT',
    ]);

    // Add employee data
    employeeData.forEach((employee) => {
      worksheet.addRow([
        employee.empCode,
        employee.firstName,
        employee.lastName,
        employee.aadharNo,
        employee.mobile,
        employee.fatherName,
        employee.address,
        employee.village,
        employee.district,
        employee.state,
        employee.pincode,
        employee.pfAccountNo,
        employee.esicno,
        employee.uan,
        employee.locationId,
        employee.departmentCode,
        employee.contractorCode,
        employee.sectionCode,
        employee.academicQualification,
        employee.designation,
        employee.jobCode,
        employee.labourType,
        employee.itemServiceCode,
        employee.bloodGroup,
        employee.bankname,
        employee.userName,
        employee.ifsc,
        employee.AccountNo,
        employee.basic,
        employee.da,
        employee.allowance1,
        employee.allowance2,
        employee.allowance3,
        employee.conveyance,
        employee.hra,
        employee.ot,
      ]);
    });

    // Generate a blob containing the Excel data
    return workbook.xlsx.writeBuffer().then((buffer) => {
      return new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
    });
  };

  const downloadExcel = () => {
    const url = `/pass/downloademployeelist`;
    const data = {
      reportType: 'all',
    };
    let responseType, fileExtension, contentType;

    responseType = 'blob';
    fileExtension = 'xlsx';
    contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    downloadEmployeeList(data, responseType)
      .then((response) => {
        const file = new Blob([response.data], { type: contentType });
        const fileUrl = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `file.${fileExtension}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    // <div>
    //   <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    //     {/* <Badge count={5}>
    //   <Avatar shape="square" size="large" />
    // </Badge> */}
    //     {/* showZero color="#faad14" size="large" */}
    //     <Button type='primary' onClick={downloadExcel}>
    //       <Tooltip title='Download Employee List'>
    //         <DownloadOutlined />
    //       </Tooltip>
    //     </Button>
    //   </div>

    //   <Divider />
    //   <h1>
    //     <b>Workmen List</b>
    //   </h1>

    //   <Table
    //     columns={columns}
    //     dataSource={Employee}
    //     onChange={onChange}
    //     pagination={{
    //       current: currentPage,
    //       pageSize: pageSize,
    //       total: totalCount, // Replace with the actual total count of your data
    //       onChange: async (page, pageSize) => {
    //         setCurrentPage(page);
    //         setPageSize(pageSize);
    //         // Fetch data for the new page and pageSize
    //         EmployeeList(pageSize, page);
    //       },
    //     }}
    //   />
    // </div>
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type='primary' onClick={downloadExcel}>
          <Tooltip title='Download Employee List'>
            <DownloadOutlined />
          </Tooltip>
        </Button>
      </div>

      <Divider />

      <h1>
        <b>Workmen List</b>
      </h1>

      <Table
        columns={columns}
        dataSource={Employee}
        onChange={onChange}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalCount,
          onChange: async (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
            EmployeeList(pageSize, page);
          },
        }}
        scroll={{ y: 400 }} // Set vertical scroll limit
        style={{ width: '100%' }}
      />
    </div>
  );
};

export default EmployeeList;
