export default function debounce(func, delay) {
  var timer;
  return function () {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, arguments);
    }, delay);
  };
}

export const currentCadre_event_mapping_HZW = {
  HA: '2 PROBATION',
  HB: '2 PROBATION',
  HC: '2 PROBATION',
  HD: '2 PROBATION',
  HE: '2 PROBATION',
  HF: '2 PROBATION',
  HG: '2 PROBATION',
  HH: '2 PROBATION',
  HI: '2 PROBATION',
  HJ: '2 PROBATION',
  HK: '2 PROBATION',
  HL: '2 PROBATION',
  W5: '2 PROBATION',
  AT0: '2 PROBATION',
  AT1: '2 PROBATION',
  AT2: '2 PROBATION',
  AT3: '2 PROBATION',
  AT4: '2 PROBATION',
  AT5: '2 PROBATION',
  ZD: '15 TEMPORARY',
  O2: '23 JOINING',
  KN: '23 JOINING',
  TWM: '15 TEMPORARY',
};

export const currentCadre_event_mapping_RNW = {
  BI: '2 PROBATION',
  BJ: '2 PROBATION',
  BK: '2 PROBATION',
  BL: '2 PROBATION',
  BM: '2 PROBATION',
  BN: '2 PROBATION',
  BO: '2 PROBATION',
  BP: '2 PROBATION',
  BQ: '2 PROBATION',
  BR: '2 PROBATION',
  BS: '2 PROBATION',
  44: '15 TEMPORARY',
  43: '23 JOINING',
  APT: '23 JOINING',
  AD1: '23 JOINING',
  AD2: '23 JOINING',
  O2: '23 JOINING',
  HM: '2 PROBATION',
  HN: '2 PROBATION',
  HO: '2 PROBATION',
  HP: '2 PROBATION',
  HQ: '2 PROBATION',
};

export const compareDateWithoutTime = (date1, date2) => {
  const dateString1 = new Date(date1)?.toISOString()?.split('T')[0];
  const dateString2 = new Date(date2)?.toISOString()?.split('T')[0];
  return dateString1.localeCompare(dateString2);
};

export const getDifferenceInDays = (date1, date2) => {
  // Convert both dates to milliseconds
  const date1Ms = new Date(date1).getTime();
  const date2Ms = new Date(date2).getTime();

  // Calculate the absolute difference in milliseconds
  const diffMs = Math.abs(date1Ms - date2Ms);

  // Convert milliseconds to days
  const diffDays = diffMs / (1000 * 60 * 60 * 24);

  return diffDays;
};
