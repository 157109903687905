import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Form,
  Select,
  Button,
  Flex,
  DatePicker,
  Row,
  Col,
  notification,
} from "antd";
import {
  getDigitalReport,
  updateDigitalReport,
  getUniqueShift,
} from "../../services/auth";
import dayjs from "dayjs";

const { Option } = Select;
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    // Whenever record changes, set the form field values
    if (editable) {
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    }
  }, [record, dataIndex, form, editable]);

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode;

  if (editable) {
    childNode = (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        initialValue={record[dataIndex]} // Set the initial value for the input
        rules={[
          {
            pattern:
              dataIndex === "onJob_hrs" ||
              dataIndex === "idle_hrs" ||
              dataIndex === "other_hrs" ||
              dataIndex === "training_hrs"
                ? /^([0-9]{1,2}):([0-5][0-9])$/
                : null, // Validation for hh-mm format
            message: "Please enter time in hh:mm format",
          },
        ]}
      >
        {dataIndex === "allowance_category" ||
        dataIndex === "allowance_hrs" ||
        dataIndex === "quality" ||
        dataIndex === "volume" ? (
          <Select
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            style={{
              minWidth: dataIndex === "allowance_category" ? "250px" : "100px",
            }} // Set width of Select to 200px
          >
            {dataIndex === "allowance_category" &&
              [
                "Hot Rolling Allowance Category 1",
                "Hot Rolling Allowance Category 2",
                "Hot Welding Allowance Category 1",
                "Hot Welding Allowance Category 2",
                "Hot Welding Allowance Category 3",
              ].map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            {dataIndex === "allowance_hrs" &&
              (record.allowance_category === "Hot Welding Allowance Category 3"
                ? ["0.5", "1", "1.5"]
                : ["0.5", "1"]
              ).map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            {dataIndex === "quality" &&
              ["1-Poor", "2-Average", "3-Good", "4-Excellent"].map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            {dataIndex === "volume" &&
              [
                "1-Below Average",
                "2-Average",
                "3-High",
                "4-Exceptionally Fast",
              ].map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
          </Select>
        ) : (
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            style={{
              width: dataIndex === "workDone_remarks" ? "200px" : "70px",
              textAlign: "center",
            }} // Set width of Input to 200px
          />
        )}
      </Form.Item>
    );
  } else {
    childNode = (
      <div
        className="editable-cell-value-wrap"
        style={{ width: dataIndex === "task_details" ? "200px" : "70px" }}
      >
        {children}
      </div>
    );
  }

  return (
    <td {...restProps} style={{ textAlign: "center" }}>
      {childNode}
    </td>
  ); // Center cell content
};

const columns = (handleSave) =>
  [
    {
      title: "Project No",
      dataIndex: "project_no",
      key: "project_no",
    },
    {
      title: "Activity",
      dataIndex: "task_details",
      key: "task_details",
      align: "center",
    },
    {
      title: "Allocation",
      dataIndex: "allocated_workman",
      key: "allocated_workman",
      render: (text, record) => {
        return ` ${record.allocated_workman} (${record.allocated_workman_id})`;
      },
    },
    {
      title: "Estimation hrs",
      dataIndex: "estimation_hrs",
      key: "estimation_hrs",
    },
    {
      title: "Booked hrs",
      dataIndex: "booked_hrs",
      key: "booked_hrs",
    },
    {
      title: "Arcing hrs",
      dataIndex: "arcing_hrs",
      key: "arcing_hrs",
    },
    {
      title: "Actual hrs",
      children: [
        {
          title: "On job",
          dataIndex: "onJob_hrs",
          key: "onJob_hrs",
          editable: true,
          align: "center",
        },
        {
          title: "Idle",
          dataIndex: "idle_hrs",
          key: "idle_hrs",
          width: 150,
          editable: true,
          align: "center",
        },
        {
          title: "Other",
          dataIndex: "other_hrs",
          key: "other_hrs",
          width: 150,
          editable: true,
          align: "center",
        },
        {
          title: "Training",
          dataIndex: "training_hrs",
          key: "training_hrs",
          width: 150,
          editable: true,
          align: "center",
        },
      ],
    },
    {
      title: "Work Done (remarks)",
      dataIndex: "workDone_remarks",
      key: "workDone_remarks",
      editable: true,
      align: "center",
    },
    {
      title: "Allowance",
      children: [
        {
          title: "Category",
          dataIndex: "allowance_category",
          key: "allowance_category",
          editable: true,
          align: "center",
        },
        {
          title: "Hours",
          dataIndex: "allowance_hrs",
          key: "allowance_hrs",
          editable: true,
          width: 150,
          align: "center",
        },
      ],
    },
    {
      title: "Performance Rating",
      children: [
        {
          title: "Quality",
          dataIndex: "quality",
          key: "quality",
          editable: true,
          align: "center",
        },
        {
          title: "Volume",
          dataIndex: "volume",
          key: "volume",
          editable: true,
          align: "center",
        },
      ],
    },
  ].map((col) => ({
    ...col,
    onCell: (record) => ({
      record,
      dataIndex: col.dataIndex,
      title: col.title,
      editable: col.editable,
      handleSave,
    }),
    children: col.children
      ? col.children.map((child) => ({
          ...child,
          onCell: (record) => ({
            record,
            dataIndex: child.dataIndex,
            title: child.title,
            editable: child.editable,
            handleSave,
          }),
        }))
      : null,
  }));

const DigitalReport = () => {
  const [dataSource, setDataSource] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Default to current date
  const [shiftList, setShiftList] = useState([]);
  const [selectedShift, setSelectedShift] = useState("");
  const [editedRows, setEditedRows] = useState(new Set());

  const fetchDigitalReport = () => {
    getDigitalReport(selectedDate?.format("DD-MM-YYYY"), selectedShift)
      .then((res) => {
        const report = res?.data?.digitalReport?.map((item, index) => ({
          ...item,
          key: item?.id || index,
        }));
        console.log('report', report)
        setDataSource(report);
        fetchUniqueShift(res?.data?.digitalReport[0]?.project_no);
        if (!res?.data?.digitalReport?.length) {
          notification.error({
            message: `No data found on ${selectedDate?.format("DD-MM-YYYY")}`,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchUniqueShift = (project_no) => {
    getUniqueShift(project_no)
      .then((res) => {
        setShiftList(res?.data);
      })
      .catch((err) => {
        console.log(err);
        notification.error({
          message: err?.response?.data?.message,
        });
      });
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    if (index > -1) {
      const item = newData[index];
      // Mark the row as edited
      newData.splice(index, 1, { ...item, ...row });
      setDataSource(newData);
      // Track the edited row by its key
      setEditedRows((prevEditedRows) => new Set([...prevEditedRows, row.key]));
    }
  };

  const handleSubmit = () => {
    const payload = dataSource.map((row) => ({
      ...row,
      // If the row's key is in the editedRows set, mark sent_to_UKG as true
      sent_to_UKG:
        row.sent_to_UKG === true && !editedRows.has(row.key)
          ? true
          : editedRows.has(row.key),
    }));
    updateDigitalReport({
      shift_data: payload,
      shift: selectedShift,
      shift_date: selectedDate?.format("DD-MM-YYYY"),
    })
      .then((res) => {
        notification.success({ message: res?.data?.message });
        setEditedRows(new Set());
        console.log(res);
      })
      .catch((err) => {
        notification.error({ message: err?.response?.data?.message });
        console.log(err);
      });
  };

  useEffect(() => {
    fetchDigitalReport();
  }, [selectedDate, selectedShift]);

  return (
    <>
      <h1>
        <b>Digital Report</b>
      </h1>
      <Row
        justify="space-between"
        align="start"
        style={{ marginBottom: "5px" }}
      >
        <Col>
          <Flex align="center" justify="start">
            <h3>Shop - W2QA</h3>
          </Flex>
        </Col>
        <Col>
          <Flex horizontal>
            <Flex gap="0" vertical>
              <p style={{ marginBottom: "5px" }}>Select Date</p>
              <DatePicker
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                format="DD-MM-YYYY"
                style={{ marginRight: "10px" }}
              />
            </Flex>
            <Flex gap="0" vertical>
              <p style={{ marginBottom: "5px" }}>Select Shift</p>
              <Select
                showSearch
                value={selectedShift}
                onChange={(value) => setSelectedShift(value)}
                style={{ width: 100 }}
                placeholder="Select shift"
              >
                {shiftList.map((shift_item) => (
                  <Option value={shift_item}>Shift - {shift_item}</Option>
                ))}
              </Select>
            </Flex>
          </Flex>
        </Col>
      </Row>
      <Form>
        <Table
          components={{
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          }}
          columns={columns(handleSave)}
          dataSource={dataSource}
          bordered
          pagination={false}
          width="100%"
          height="500px"
          scroll={{ x: "max-content", y: 70 * 4 }} // Horizontal scroll for large tables
          sticky
        />
        <Flex justify="end">
          <Button
            type="primary"
            onClick={handleSubmit}
            style={{ marginTop: "20px" }}
          >
            Submit
          </Button>
        </Flex>
      </Form>
    </>
  );
};

export default DigitalReport;
