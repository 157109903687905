import React from 'react';
import { Button, Steps } from 'antd';
import { useParams } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import ApproverSummary from './ApproverSummary';
import Medical from '../components/Approver2/Medical';
import Safety from '../components/Approver2/Safety';

export default function MedicalApproverStepper() {
  const { user, userConfig } = useAuth();
  const params = useParams();

  const token = params.token;

  const [currentPageNo, setCurrentPageNo] = React.useState(0);
  const [Tab, setTab] = React.useState('Profile Summary');

  const pageItems = [
    {
      key: 'orgInfo',
      title: 'Profile Summary',
    },
    {
      key: 'location',
      title: 'Medical Approval',
    },
  ];

  const next = (values) => {
    if (currentPageNo === 0) {
      setTab('Medical Approval');
    }

    if (currentPageNo === 1) {
      setTab('Profile Summary');

      return;
    }

    setCurrentPageNo(currentPageNo + 1);
  };

  const prev = (values) => {
    if (currentPageNo === 1) {
      setTab('Profile Summary');
    }

    if (currentPageNo === 2) {
      setTab('Medical Approval');
    }

    setCurrentPageNo(currentPageNo - 1);
  };

  return (
    <>
      <h2>{Tab}</h2>

      <Steps
        current={currentPageNo}
        items={pageItems}
        style={{ marginBottom: '10px' }}
      />

      {currentPageNo === 0 && <ApproverSummary />}
      {currentPageNo === 1 && <Medical />}

      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        {currentPageNo > 0 && (
          <Button onClick={prev} style={{ marginLeft: 'auto' }}>
            Previous
          </Button>
        )}
        {currentPageNo < pageItems.length - 1 && (
          <Button type='primary' onClick={next} style={{ marginLeft: 'auto' }}>
            Next
          </Button>
        )}
      </div>
    </>
  );
}
