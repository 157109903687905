import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Space,
  Input,
  Form,
  Table,
  Typography,
  Popconfirm,
  notification,
} from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  EditFilled,
  InteractionOutlined,
} from '@ant-design/icons';
import { getContractorList } from '../../services/auth';
import Highlighter from 'react-highlight-words';
import AddContractor from '../Contractor/AddContractor';
import ReplaceContractor from '../Contractor/replaceContractor';
import dayjs from 'dayjs';
import { Editcontractor } from '../../services/auth';
import AddJobDetails from './AddJobDetails';
import useAuth from '../../hooks/useAuth';

export default function JobOrderDetails({
  initialValues,
  onPrev,
  onNext,
  locationsData,
}) {
  const [sortedInfo, setSortedInfo] = React.useState({});
  const [, forceUpdate] = React.useState({});
  const [Supplier, setSupplier] = React.useState([]);
  const [, setloading] = React.useState(true);
  const [, setEditingKey] = React.useState('');
  const formRef = React.useRef();

  // search icon
  const [searchText, setSearchText] = React.useState('');
  const [searchedColumn, setSearchedColumn] = React.useState('');
  const [adminFormOpen, setAdminFormOpen] = React.useState(false);
  const [replaceOpen, setReplaceOpen] = React.useState(false);
  const [selectedContractor, setSelectedContractor] = useState({});
  const { userConfig } = useAuth();
  const roleName = userConfig?.roleName;

  // const [data, setData] = React.useState(initialValues);

  const DEFAULT_ADMIN_FORM_VALUE = {
    id: '',
    name: '',
    maxContractors: '',
    // doe: "",
    // logoFile: "",
  };
  const [formInitialValue, setFormInitialValue] = React.useState(
    DEFAULT_ADMIN_FORM_VALUE
  );
  const [editIndex, setEditIndex] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);

  const searchInput = useRef(null);

  useEffect(() => {
    ContractorList();
    forceUpdate({});
  }, []);

  const ContractorList = async () => {
    getContractorList().then((res) => {
      setloading(false);
      // setSupplier(
      //   res.data.suppliers.map((row) => ({
      //     _id: row._id,
      //     key: row.id,
      //     id: row.id,
      //     name: row.name,
      //     status: row.status,
      //     jobordernumber: row.addJobDetails.jobordernumber,
      //     // doe: row.doe,
      //     doe: dayjs(row.doe).format('DD-MM-YYYY'),
      //   }))
      // );

      if (res && res.data && res.data.suppliers) {
        const formattedContractors = res.data.suppliers.flatMap(
          (contractor) => {
            if (
              contractor.addJobDetails &&
              contractor.addJobDetails.length > 0
            ) {
              // Extract JobOrderNumbers from addJobDetails
              const jobOrderNumbers = contractor.addJobDetails.map(
                (jobDetails) => ({
                  jobordernumber: jobDetails.jobordernumber,
                  periodContractorEndDate: jobDetails.periodContractorEndDate,
                  newStatus: jobDetails.newStatus,
                })
              );

              // Create an array of objects for each JobOrderNumber
              return jobOrderNumbers.map((jobOrderNumber) => {
                const formattedData = {
                  _id: contractor._id,
                  Name: contractor.name || 'N/A',
                  ID: contractor.id || 'N/A',
                  Status: contractor.status || 'N/A',
                  Edited: contractor.Edited || 'N/A',
                  Location: contractor.location,

                  // DOE: dayjs(contractor.doe).format('DD-MM-YYYY') || 'N/A',
                  JobOrderNumber: jobOrderNumber.jobordernumber,
                  periodContractorEndDate:
                    jobOrderNumber.periodContractorEndDate
                      ? dayjs(jobOrderNumber.periodContractorEndDate).format(
                          'DD-MM-YYYY'
                        )
                      : '', // Add more properties as needed
                  newStatus: jobOrderNumber?.newStatus,
                };

                // Add details from addJobDetails
                const jobDetailsForOrderNumber = contractor.addJobDetails.find(
                  (jobDetails) => jobDetails.jobordernumber === jobOrderNumber
                );

                if (jobDetailsForOrderNumber) {
                  formattedData.AdditionalDetails = jobDetailsForOrderNumber;
                }

                return formattedData;
              });
            } else {
              return [
                // No addJobDetails, so add a default object
                {
                  _id: contractor._id,
                  Name: contractor.name || 'N/A',
                  ID: contractor.id || 'N/A',
                  Status: contractor.status || 'N/A',
                  Edited: contractor.Edited || 'N/A',
                  Location: contractor.location,
                  // DOE: dayjs(contractor.doe).format('DD-MM-YYYY') || 'N/A',
                  JobOrderNumber: 'N/A', // Set a default value
                  // Add more properties as needed
                },
              ];
            }
          }
        );

        setSupplier(
          formattedContractors?.map((row) => ({
            _id: row._id,
            key: `${row._id}-${row.JobOrderNumber}`,
            id: row.ID,
            name: row.Name,
            // status: row.Status,
            status: row.Status[0].toUpperCase() + row.Status.slice(1),
            jobordernumber: row.JobOrderNumber,
            location: row.Location,
            // doe: row.doe,
            periodContractorEndDate: row.periodContractorEndDate
              ? row.periodContractorEndDate
              : '--',
            Edited: row?.Edited,
            newStatus: row?.newStatus,
            // jobId: row.id,
          }))
        );
      }
    });
  };

  let filteredData;
  if (userConfig.role == 'contractor') {
    filteredData = Supplier.filter((supplier) => {
      if (userConfig?.supplierId) {
        return (
          userConfig.supplierId == supplier.id &&
          supplier.jobordernumber !== 'N/A'
        );
      } else {
        return (
          userConfig.userid == supplier.id && supplier.jobordernumber !== 'N/A'
        );
      }
    });
  } else {
    filteredData = Supplier;
  }
  if (userConfig.role == 'approver') {
    filteredData = Supplier.filter(
      (supplier) =>
        supplier.Edited === 'Yes' && supplier.jobordernumber !== 'N/A'
    );
  } else {
    // filteredData = Supplier;
  }

  const edit = (record) => {
    setAdminFormOpen(true);
    setEditIndex(record.index);
    setIsEdit(true);
    //  setFormInitialValue(
    //   data.find((d) => d.id === record.id) || DEFAULT_ADMIN_FORM_VALUE
    // );
    Editcontractor({ id: record.id })
      .then((res) => {
        //
        const contractorId = record.id;
        const jobOrderNumber = record.jobordernumber;
        //
        //
        //

        // Find the element in the response based on id and jobOrderNumber
        const matchingItem = res.data.addJobDetails.find(
          (item) =>
            item.id === contractorId &&
            String(item.jobordernumber) === String(jobOrderNumber)
        );

        if (matchingItem) {
        }
        const Response = matchingItem;
        console.log(Response?.pfApplyDate, 'pfApplyDate');
        let data = {
          key: Response.id,
          id: Response.id,
          // name: res.data.name,
          // address1: res.data.address1,
          // address2: res.data.address2,
          // address3: res.data.address3,
          // district: res.data.district,
          // pincode: res.data.pincode,
          // emailId: res.data.emailId,
          // mobileNo: res.data.mobileNo,
          // principalEmpCode: res.data.principalEmpCode,
          // unitId: res.data.unitId,
          // managerId: res.data.managerId,
          poolWorkmen: Response.poolWorkmen,
          pfEstablishmentSubCode: Response.pfEstablishmentSubCode,
          periodContractorStartDate: dayjs(
            new Date(Response.periodContractorStartDate)
          ),
          periodContractorStartDate: dayjs(
            new Date(Response.periodContractorEndDate)
          ),

          pfApplyDate: Response.pfApplyDate
            ? dayjs(new Date(Response.pfApplyDate))
            : '',
          periodContractorStartDate: Response.periodContractorStartDate
            ? dayjs(new Date(Response.periodContractorStartDate))
            : '',
          avrageDeployement: Response.avrageDeployement,
          periodContractorEndDate: Response.periodContractorEndDate
            ? dayjs(new Date(Response.periodContractorEndDate))
            : '',
          esiValidFrom: dayjs(new Date(Response.esiValidFrom)),
          esiValidTo: dayjs(new Date(Response.esiValidTo)),
          // managerName: res.data.managerName,
          //natureOfWork: res.data.natureOfWork,
          totalLicenceCoverage: Response.totalLicenceCoverage,
          maxContractors: Response.maxContractors,
          // doe: dayjs(new Date(Response.doe)),
          llregdate: Response?.llregdate
            ? dayjs(new Date(Response.llregdate))
            : '',
          doe: Response.doe ? dayjs(new Date(Response.doe)) : '',
          llf: Response.llf ? dayjs(new Date(Response.llf)) : '',
          wcecRegisterDate: Response.wcecRegisterDate
            ? dayjs(new Date(Response.wcecRegisterDate))
            : '',
          //location: res.data.location,
          esicno: Response.esicno,
          pfAccountNo: Response.pfAccountNo,
          jobordernumber: Response.jobordernumber,
          // llf: dayjs(new Date(Response.llf)),
          lln: Response.lln,
          // logoFile: res.data.logoFile,
          nowc: Response.nowc,
          wcpn: Response.wcpn,
          noofWorkmenCovered: Response.noofWorkmenCovered,
          wcvf: dayjs(new Date(Response.wcvf)),
          // uploadDocument: res.data.uploadDocument,
          wcvt: dayjs(new Date(Response.wcvt)),
          // faxNo: res.data.faxNo,
          // ownerName: res.data.ownerName,
          // contactNumber: res.data.contactNumber,
          // pan: res.data.pan,
          // serviceTaxNumber: res.data.serviceTaxNumber,
          blockedType: Response.blockedType,
          blockedFrom: dayjs(new Date(Response.blockedFrom)),
          blockedTo: dayjs(new Date(Response.blockedTo)),
          blockedRemark: Response.blockedRemark,
          //agencyEmailId: res.data.agencyEmailId,
          // sapVendorCode: res.data.sapVendorCode,
          // ownerContactNo: res.data.ownerContactNo,
          // ownerEmailId: res.data.ownerEmailId,
          //typeOfContractor: res.data.typeOfContractor,
          //proprietorship: res.data.proprietorship,
          // foreignNumber: res.data.foreignNumber,
          // state: res.data.state,
          // village: res.data.village,
          // taluka: res.data.taluka,
          // pdistrict: res.data.pdistrict,
          // ppincode: res.data.ppincode,
          // pcountry: res.data.pcountry,
          // cdistrict: res.data.cdistrict,
          // cpincode: res.data.cpincode,
          // ccountry: res.data.ccountry,
          // tehsil: res.data.tehsil,
          // city: res.data.city,
          // country: res.data.country,
          // paddress1: res.data.paddress1,
          // paddress2: res.data.paddress2,
          // paddress3: res.data.paddress3,
          // pstate: res.data.pstate,
          // pvillage: res.data.pvillage,
          // ptaluka: res.data.ptaluka,
          // ptehsil: res.data.ptehsil,
          // pcity: res.data.pcity,
          // caddress1: res.data.caddress1,
          // caddress2: res.data.caddress2,
          // caddress3: res.data.caddress3,
          // cstate: res.data.cstate,
          // cvillage: res.data.cvillage,
          // ctaluka: res.data.ctaluka,
          // ctehsil: res.data.ctehsil,
          // ccity: res.data.ccity,
          // contactNo: res.data.contactNo,
          gstNo: Response.gstNo,
          vatNo: Response.vatNo,
          noOfWorkersUsed: Response.noOfWorkersUsed,
          insuranceCompanyName: Response.insuranceCompanyName,
        };

        setFormInitialValue(data);
      })
      .catch((err) => {});
  };

  // const validateInsurance = ({ nowc, noofWorkmenCovered, maxContractors }) => {
  //   const sumNowcNoofWorkmen = parseInt(nowc, 10) + parseInt(noofWorkmenCovered, 10);

  //   if (parseInt(maxContractors, 10) < sumNowcNoofWorkmen) {
  //     // Trigger your validation logic here
  //
  //   }
  // };

  const replaceContractor = (record) => {
    setSelectedContractor(record);
    setReplaceOpen(true);
  };

  const onFinish = (values) => {};
  const openAdminModal = () => {
    setIsEdit(false);
    setAdminFormOpen(true);
  };

  // pagination
  const cancel = () => {
    setEditingKey('');
  };
  // Handle Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}>
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '25%',

      key: 'name',
      ...getColumnSearchProps('name'),
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, record) => record.name.startsWith(value),
    },
    {
      title: 'Contractor Code',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id'),
      filterMode: 'tree',
      filterSearch: true,
      // onFilter: (value, record) => record.id.startsWith(value),
      // width: '10%',
      // sorter: (a, b) => a.id.length - b.id.length,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      ellipsis: true,
    },

    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   key: 'status',
    // },
    {
      title: 'Status',
      dataIndex: 'newStatus',
      key: 'newStatus',
    },
    {
      title: 'Job Order Number',
      dataIndex: 'jobordernumber',
      key: 'jobordernumber',
    },
    {
      title: 'Expiry Date',
      dataIndex: 'periodContractorEndDate',
      key: 'periodContractorEndDate',
      render: (date) => {
        return date ? date : ''; // Display the date if available, otherwise display an empty string
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record, _id) => {
        return (
          <Space>
            <Typography.Link
              disabled={
                userConfig.role === 'admin'
                  ? userConfig.roleName &&
                    !roleName?.permissions?.Contractors?.edit
                  : ''
              }
              onClick={() => edit(record)}>
              <EditFilled />
            </Typography.Link>
            {/* <Typography.Link onClick={() => replaceContractor(record)}>
              <InteractionOutlined />{' '}
            </Typography.Link> */}
          </Space>
        );
      },
    },
  ];
  const onChange = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
  };

  return (
    <>
      {/* <h2>Contractor Agency Onboarding</h2> */}
      <Form
        name='orgOnboard'
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}
        ref={formRef}>
        <Form.Item>
          {userConfig.role !== 'approver' &&
            userConfig.role !== 'approver level one' && (
              <Button
                type='dashed'
                onClick={openAdminModal}
                block
                icon={<PlusOutlined />}
                disabled={
                  userConfig.role === 'admin'
                    ? userConfig.roleName &&
                      !roleName?.permissions?.Contractors?.create
                    : ''
                }>
                Add JO & Statutory Details
              </Button>
            )}
        </Form.Item>
        <AddJobDetails
          open={adminFormOpen}
          onCancel={() => {
            setFormInitialValue({});
            setAdminFormOpen(false);
          }}
          initialValues={formInitialValue}
          // onOk={addAdmin}
          isEdit={isEdit}
          supplierList={Supplier}
          locationsData={locationsData}
        />
        {/* {replaceOpen && (
          <ReplaceContractor
            open={replaceOpen}
            onCancel={() => setReplaceOpen(false)}
            initialValues={selectedContractor}
            contractorsList={Supplier}
          />
        )} */}
        {/* <Table
          columns={columns}
          dataSource={Supplier.filter(
            (supplier) => supplier.jobordernumber !== 'N/A'
          )}
          dataSource={filteredData}
          onChange={onChange}
          pagination={true}
        /> */}
        <Table
          columns={columns}
          dataSource={
            userConfig.role === 'admin'
              ? Supplier.filter((supplier) => supplier.jobordernumber !== 'N/A')
              : filteredData
          }
          onChange={onChange}
          pagination={true}
        />
        ;
        <Space
          style={{ width: '100%', justifyContent: 'end', marginTop: '24px' }}>
          <Button onClick={() => onPrev()}>Prev</Button>
          {/* <Form.Item noStyle>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item> */}
        </Space>
      </Form>
    </>
  );
}
