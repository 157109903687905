import React, { useEffect } from 'react';
import {
  Button,
  Form,
  notification,
  Popconfirm,
  Space,
  Table,
  Typography,
} from 'antd';
import { PlusOutlined, DeleteFilled, EditFilled } from '@ant-design/icons';
import SupervisorFormModal from './SupervisorFormModal';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { Email, getContractorOnboard } from '../../services/auth';
import axios from '../../services/axiosInstance';
import ContractorSupervisorModal from './ContractorSupervisorModal';

const DEFAULT_ADMIN_FORM_VALUE = {
  firstName: '',
  lastName: '',
  role: 'supervisor',
  roleName: '',
  supplierId: '',
  unitId: '',
  location: '',
  userid: '',
  password: '',
  emailId: '',
};

export default function ContractorSupervisorForm({
  initialValues,
  locationsData,
  onNext,
  onPrev,
}) {
  const [data, setData] = React.useState(initialValues);
  const { userConfig } = useAuth();
  const roleName = userConfig?.roleName;

  const [formInitialValue, setFormInitialValue] = React.useState(
    DEFAULT_ADMIN_FORM_VALUE
  );
  const formRef = React.useRef();
  const navigate = useNavigate();

  const [editIndex, setEditIndex] = React.useState(null);
  // const isEdit = editIndex !== null;
  const [isEdit, setIsEdit] = React.useState(false);
  const [users, setUsers] = React.useState(false);

  const [adminFormOpen, setAdminFormOpen] = React.useState(false);

  React.useEffect(() => {
    EmployeeList();
    OrganizationInfo();
  }, []);

  const [orgSupervisorData, setOrgSupervisorData] = React.useState();

  const OrganizationInfo = async () => {
    getContractorOnboard().then((res) => {
      const orgsup = res.data.org.orgUsers;

      const orgsup1 = orgsup.shift();

      const orgData = res.data.org;
      const orgDataLocation = res.data.org.locations;

      setOrgSupervisorData(orgsup.map((d) => ({ ...d, id: d._id })));

      // setLocationsData({Approver:Approver})
    });
  };

  async function EmployeeList() {
    try {
      const response = await axios.get('/supplier');
      const responseData = response.data.suppliers;

      setUsers(responseData || []); // Use an empty array as fallback if responseData is undefined
    } catch (error) {
      console.error('Error fetching employee list:', error);
    }
  }

  console.log('orgSupervisorData88', orgSupervisorData);

  const tableData = React.useMemo(() => {
    return orgSupervisorData?.map((userData, index) => ({
      index: index,
      name: userData?.firstName + ' ' + userData?.lastName,
      userid: userData?.userid,
      role: userData?.role[0]?.toUpperCase() + userData?.role?.slice(1),
      supplierID: userData?.supplierId,
      supplierId: Array.isArray(users)
        ? users
            .filter((resData) =>
              resData.addJobDetails.some(
                (contractorCodeId) =>
                  contractorCodeId?.id === userData?.supplierId
              )
            )
            .map((resData) => resData?.name)
        : [],
      location: userData?.location,
    }));
  }, [orgSupervisorData, users]);
  const adminData = tableData?.filter((item) => item?.role === 'Supervisor');

  const dataSource = userConfig?.locationId
    ? adminData?.filter((item) => {
        console.log('item----95', item);
        const isAdmin = item.role === 'Supervisor';
        const isMatchingLocation = item.location === userConfig.location;
        const isMatchingAgency = item.supplierID === userConfig.supplierId;

        return isMatchingAgency;
      })
    : adminData;

  const openAdminModal = () => {
    setIsEdit(false);
    setFormInitialValue(DEFAULT_ADMIN_FORM_VALUE);
    setAdminFormOpen(true);
  };

  const addAdmin = (value, isEdit = false) => {
    if (isEdit) {
      setData((currData) => {
        return currData?.map((d, index) => {
          if (index === editIndex) {
            return value;
          }

          return d;
        });
      });

      setEditIndex(null);
    } else {
      setData((currData) => [value]);
    }
    setAdminFormOpen(false);
  };

  const edit = (record) => {
    setIsEdit(true);

    setEditIndex(record.index);
    setFormInitialValue(
      orgSupervisorData?.find((d) => d?.userid === record?.userid) ||
        DEFAULT_ADMIN_FORM_VALUE
    );
    setAdminFormOpen(true);
  };

  const deleteRecord = (record) => {
    setData(data?.filter((_, i) => record.index !== i));
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '20%',
      editable: true,
    },
    {
      title: 'User ID',
      dataIndex: 'userid',
      width: '20%',
      editable: true,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      width: '20%',
      editable: true,
    },
    {
      title: 'ContractorAgency Name',
      dataIndex: 'supplierId',
      width: '20%',
      editable: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '20%',

      render: (_, record) => {
        return (
          <Space>
            <Typography.Link
              disabled={
                userConfig.roleName && !roleName?.permissions?.Org_Setup?.edit
              }
              onClick={() => edit(record)}>
              <EditFilled />
              Edit
            </Typography.Link>
            {/* <Popconfirm title='Delete' onConfirm={() => deleteRecord(record)}>
              <Typography.Link disabled={userConfig.roleName && !roleName?.Principal_Employer?.edit}>
                <DeleteFilled />
                Delete
              </Typography.Link>
            </Popconfirm>  */}
          </Space>
        );
      },
    },
  ];

  const checkIsValidAddition = (userData = {}) => {
    if (userData) {
      if (
        data?.filter(
          (user) =>
            user.userid !== formInitialValue.userid &&
            user.userid === userData.userid
        ).length > 0
      ) {
        notification.error({ message: 'User ID already exists' });
        return false;
      }

      if (
        data?.filter(
          (user) =>
            user.emailId !== formInitialValue.emailId &&
            user.emailId === userData.emailId
        ).length > 0
      ) {
        notification.error({ message: 'Email ID already exists' });
        return false;
      }

      return true;
    }
    return false;
  };

  const Title = () => {
    return (
      <Form.Item>
        <Button
          type='dashed'
          onClick={openAdminModal}
          block
          disabled={
            userConfig.roleName && !roleName?.permissions?.Org_Setup?.create
          }
          icon={<PlusOutlined />}>
          Add Supervisor
        </Button>
      </Form.Item>
    );
  };

  const onFinish = (values) => {
    navigate('/home/admin');

    //

    onNext(data);
  };

  return (
    <Space
      direction='vertical'
      style={{ margin: '22px 12px', display: 'flex' }}>
      <Table
        bordered
        dataSource={dataSource}
        columns={columns}
        title={Title}
        rowKey='userid'
      />
      <Form initialValues={initialValues} ref={formRef} onFinish={onFinish}>
        <ContractorSupervisorModal
          open={adminFormOpen}
          onOk={addAdmin}
          onCancel={() => setAdminFormOpen(false)}
          checkIsValidAddition={checkIsValidAddition}
          initialValues={formInitialValue}
          isEdit={isEdit}
          locationsData={locationsData}
        />
      </Form>
    </Space>
  );
}
