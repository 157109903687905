import React from 'react';
import { Col, Row, Form, Button, Typography, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import useAuth from '../../hooks/useAuth';
import '../EmployeeOnboard/summaryStyle.css';

const { Title } = Typography;

const Summarygeneration = ({
  initialValues,
  onNext,
  onPrev,
  jobDetails,
  wageDetails,
  masterData,
}) => {
  const navigate = useNavigate();
  const formRef = React.useRef();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onFinish = (values) => {
    onNext(values);
    navigate('/home/ir/employee');
    // window.location.reload();
  };
  /* eslint-disable */
  const { user, userConfig } = useAuth();

  const [data, setData] = React.useState(initialValues);

  const [jobdata, setsetData] = React.useState(jobDetails);

  const [wagedata, setwageData] = React.useState(wageDetails);
  const date = dayjs(data.Dob);
  const formattedDate = date.format('DD-MM-YYYY');
  //-----------------------
  const fatherDob = dayjs(data.dobFather);
  const dlExpiry = fatherDob.format('DD-MM-YYYY');
  //-----------------------
  const Dob = dayjs(data.dobMother);
  const motherDob = Dob.format('DD-MM-YYYY');
  //-----------------------
  const date2 = dayjs(jobdata.doj);
  const doj = date2.format('DD-MM-YYYY');
  //-----------------------
  const date3 = dayjs(jobdata.effectiveDate);
  const effectiveDate = date3.format('DD-MM-YYYY');
  //-------------------------
  // const date4 = dayjs(jobdata.subSkillDate);
  // const subSkillDate = jobdata.subSkillDate.format("DD-MM-YYYY");

  //For Description

  React.useEffect(() => {
    cadreCodeMasterInput();
  }, [masterData]);

  const [cadreCodeMaster, setCadreCodeMaster] = React.useState([]);
  const [drmr, setDrmr] = React.useState([]);

  function cadreCodeMasterInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Cadre_Master_Regular';
    //
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );

    setDrmr(dataFromArray?.dataParsed);
    const functionCadreCodeMasterInputAreas = dataFromArray?.dataParsed?.map(
      (item) => {
        //
        return item?.Cadre_Code + ' ' + item?.Cadre_Description;
        // return item?.Cadre_Description;
      }
    );
    //
    setCadreCodeMaster(functionCadreCodeMasterInputAreas);
    //

    if (dataFromArray) {
      console.log(
        'functionCadreCodeMasterInputAreas',
        functionCadreCodeMasterInputAreas
      );
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  const codeDescriptionMap = {};
  console.log('cadreCodeMaster455', cadreCodeMaster);
  cadreCodeMaster?.forEach((area) => {
    const [code, ...descriptionParts] = area.split(' ');
    const description = descriptionParts.join(' ');
    codeDescriptionMap[code] = description;
  });

  // Look up the description using the code
  const currentCadreDescription =
    codeDescriptionMap[jobdata.currentCadre] || jobdata.currentCadre;

  const CadreAtJoiningDescription =
    codeDescriptionMap[jobdata.cadreAtJoining] || jobdata.currentCadre;

  return (
    <div>
      <Form initialValues={initialValues} ref={formRef} onFinish={onFinish}>
        <Row class='row'>
          <Col span={12} class='column'>
            <Title level={5} style={{ color: 'red' }}>
              Personal Details
            </Title>
            <Row>
              <span>First Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.firstName}</span>
            </Row>
            <Row>
              <span>Last Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.lastName}</span>
            </Row>
            <Row>
              <span>Long Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.LongName}</span>
            </Row>
            <Row>
              <span>Gender</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.gender}</span>
            </Row>
            <Row>
              <span>Date Of Birth</span>
              <span class='span2'>:</span>
              <span class='span3'>{formattedDate}</span>
            </Row>
            <Row>
              <span>Aadhaar Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{`XXXX XXXX ${`${data.aadharNo}`.slice(
                -4
              )}`}</span>
            </Row>
            <Row>
              <span>PAN</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pan}</span>
            </Row>
            <Row>
              <span>Father Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.fatherName}</span>
            </Row>
            <Row>
              <span>DOB Of Father</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {data?.dobFather ? data?.dobFather.format('DD-MM-YYYY') : ''}
              </span>
            </Row>
            <Row>
              <span>Mother Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.motherName}</span>
            </Row>
            <Row>
              <span>DOB Of Mother</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {data.dobMother ? data.dobMother.format('DD-MM-YYYY') : ''}
              </span>
            </Row>
            <Row>
              <span>Health Issue</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.healthIssue}</span>
            </Row>
            <Row>
              <span>Extra Information</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.extraInformation}</span>
            </Row>
            <Row>
              <span>Age</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.age}</span>
            </Row>
            <Row>
              <span>Initials</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.initials}</span>
            </Row>
            <Row>
              <span>Birth Place</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.birthPlace}</span>
            </Row>
            <Row>
              <span>No. Of Dependents</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.noOFDependents}</span>
            </Row>
            <Row>
              <span>Nominee Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.nomineeName}</span>
            </Row>
            <Row>
              <span>Nomilnee Relationship</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.nomineeRelationship}</span>
            </Row>
            <Row>
              <span>Nominee Contact Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.nomineeContactnumber}</span>
            </Row>
            <Row>
              <span>No. Of Children</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.noOFChildren}</span>
            </Row>
            <Row>
              <span>Marital Status</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.martialStatus}</span>
            </Row>
            <Row>
              <span>Name of Spouse</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.spousename}</span>
            </Row>
            <Row>
              <span>Relation</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.relation}</span>
            </Row>
            <Row>
              <span>Qualification of Spouse</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.qualificationOfSpouse}</span>
            </Row>
            <Row>
              <span>Occupation of Spouse</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.occupationOfSpouse}</span>
            </Row>
            <Row>
              <span>DOB of Spouse</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {data.dobOFSpouse ? data.dobOFSpouse.format('DD-MM-YYYY') : ''}
              </span>
            </Row>
            <Row>
              <span>Date Of Marriage</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {data.dobOFMarriage
                  ? data.dobOFMarriage.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Child Details</span>
              <span class='span2'>:</span>
              <span class='span3'>{data?.childrenNo}</span>
            </Row>
            {Object.keys(data)
              .filter((key) => key.startsWith('name'))
              .map((key, index) => (
                <div key={index}>
                  <Row>
                    <span>
                      <b>{[`Child Number ${index + 1}`]}</b>
                    </span>
                  </Row>
                  <Row>
                    <span>Name</span>
                    <span className='span2'>:</span>
                    <span className='span3'>{data[`name${index + 1}`]}</span>
                  </Row>
                  <Row>
                    <span>Male/Female</span>
                    <span className='span2'>:</span>
                    <span className='span3'>{data[`male${index + 1}`]}</span>
                  </Row>
                  <Row>
                    <span>Birth Date</span>
                    <span className='span2'>:</span>
                    {/* <span className='span3'>
                      {data[`birthDate${index + 1}`]
                        ? new Date(
                            data[`birthDate${index + 1}`]
                          ).toLocaleDateString('en-GB')
                        : ''}
                    </span> */}
                    <span className='span3'>
                      {data[`birthDate${index + 1}`]
                        ? new Date(data[`birthDate${index + 1}`])
                            .toLocaleDateString('en-GB')
                            .replace(/\//g, '-')
                        : ''}
                    </span>
                  </Row>
                  <Row>
                    <span>Medium</span>
                    <span className='span2'>:</span>
                    <span className='span3'>{data[`medium${index + 1}`]}</span>
                  </Row>
                  <Row>
                    <span>Occupation</span>
                    <span className='span2'>:</span>
                    <span className='span3'>
                      {data[`occupation${index + 1}`]}
                    </span>
                  </Row>
                  <Row>
                    <span>Mediclaim</span>
                    <span className='span2'>:</span>
                    <span className='span3'>
                      {data[`mediclaim${index + 1}`]}
                    </span>
                  </Row>
                  <Row>
                    <span>Eff.Date</span>
                    <span className='span2'>:</span>
                    {/* <span className='span3'>
                      {data[`effDate${index + 1}`]
                        ? new Date(
                            data[`effDate${index + 1}`]
                          ).toLocaleDateString('en-GB')
                        : ''}
                    </span> */}
                    <span className='span3'>
                      {data[`effDate${index + 1}`]
                        ? new Date(data[`effDate${index + 1}`])
                            .toLocaleDateString('en-GB')
                            .replace(/\//g, '-')
                        : ''}
                    </span>
                  </Row>
                </div>
              ))}
            <Row>
              <span>Religion</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.religion}</span>
            </Row>
            <Row>
              <span>Caste</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.caste}</span>
            </Row>
            <Row>
              <span>Domicile</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.domicile}</span>
            </Row>
            <Row>
              <span>Based In Gujarat</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.basedinGujarath}</span>
            </Row>
            {/* <Row>
              <span>Number Of year spent in Gujarat</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.numberOfYearSpent}</span>
            </Row> */}
            <Row>
              <span>Years In Gujarat(Since When)</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {data.yearsInGujarath
                  ? data.yearsInGujarath.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Disabled</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.disable}</span>
            </Row>
            <Row>
              <span>Disability By Birth or while Working</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.disabilityByBirth}</span>
            </Row>
            <Row>
              <span>Disability Type</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.disabilityType}</span>
            </Row>
            <Row>
              <span>Disability %</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.disabilityPercentage}</span>
            </Row>
            <Row>
              <span>Workmen Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.empCode}</span>
            </Row>
            <Row>
              <span>Unit Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.unitName}</span>
            </Row>
            <Row>
              <span>Location Id</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.locationId}</span>
            </Row>
            <Row>
              <span>Experience Details</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.experienceDetails}</span>
            </Row>{' '}
            <Row>
              <span>Email Id</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.emailId}</span>
            </Row>
            <Row>
              <span>PF Exempt</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pfAccountNo ? 'No' : 'Yes'}</span>
            </Row>
            <Row>
              <span>PF Account Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pfAccountNo}</span>
            </Row>
            <Row>
              <span>ESIC Exempt</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.esicno ? 'No' : 'Yes'}</span>
            </Row>
            <Row>
              <span>ESIC Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.esicno}</span>
            </Row>
            <Row>
              <span>UAN Exempt</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.uan ? 'No' : 'Yes'}</span>
            </Row>
            <Row>
              <span>UAN</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.uan}</span>
            </Row>
            <Title level={5}>Aadhaar Addess</Title>
            <Row>
              <Col>Permanent Address Line 1</Col>
              <span class='span2'>:</span>
              <span class='address'>{data.address1}</span>
            </Row>
            <Row>
              <span>Permanent Address Line 2</span>
              <span class='span2'>:</span>
              <span class='address'>{data.address2}</span>
            </Row>
            <Row>
              <span>Permanent Address Line 3</span>
              <span class='span2'>:</span>
              <span class='address'>{data.address3}</span>
            </Row>
            <Row>
              <span>Village</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pvillage}</span>
            </Row>
            <Row>
              <span>District</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.district}</span>
            </Row>
            <Row>
              <span>State</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.state}</span>
            </Row>
            <Row>
              <span>Pincode</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pincode}</span>
            </Row>
            <Row>
              <span>Contact Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.mobile}</span>
            </Row>
            <Row>
              <span>Post Office</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.postOffice}</span>
            </Row>
            <Title level={5}>Present Address</Title>
            <Row>
              <Col>Present Address Line 1</Col>
              <span class='span2'>:</span>
              <span class='paddress'>{data.paddress1}</span>
            </Row>
            <Row>
              <span>Present Address Line 2</span>
              <span class='span2'>:</span>
              <span class='paddress'>{data.paddress2}</span>
            </Row>
            <Row>
              <span>Present Address Line 3</span>
              <span class='span2'>:</span>
              <span class='paddress'>{data.paddress3}</span>
            </Row>
            <Row>
              <span>Village</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pvillage}</span>
            </Row>
            <Row>
              <span>District</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pdistrict}</span>
            </Row>
            <Row>
              <span>State</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pstate}</span>
            </Row>
            <Row>
              <span>Pincode</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.ppincode}</span>
            </Row>
            <Row>
              <span>Contact Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pmobile}</span>
            </Row>
            <Row>
              <span>Post Office</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.ppostOffice}</span>
            </Row>
            <hr />
            <Title level={5} style={{ color: 'red' }}>
              Wage Details
            </Title>
            <Row>
              <span>Daily/Monthly Wages</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.dailywages}</span>
            </Row>
            <Row>
              <span>Current Basic</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.currentBasic}</span>
            </Row>
            <Row>
              <span>Joining Basic</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.joiningBasic}</span>
            </Row>
            <Row>
              <span>Confirmation Basic</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {wagedata.confirmationBasic ? wagedata.confirmationBasic : '0'}
              </span>
            </Row>
          </Col>

          <Col span={12} class='column2' style={{ paddingLeft: '50px' }}>
            <Title level={5} style={{ color: 'red' }}>
              Job Details
            </Title>
            <Row>
              <span>Company</span>
              <span class='span2'>:</span>
              <span class='span3'>{userConfig?.organisation?.name}</span>
            </Row>
            <Row>
              <span>Dept Head</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.deptHead}</span>
            </Row>
            <Row>
              <span>Working Area</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.workingArea}</span>
            </Row>
            <Row>
              <span>Function Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.functionCode}</span>
            </Row>
            <Row>
              <span>Immediate Adm. Supervisor</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.immediateadmsupervisor}</span>
            </Row>
            <Row>
              <span>Immediate Func. Supervisor</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.immediatefuncsupervisor}</span>
            </Row>
            <Row>
              <span>Next Func. Supervisor</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.nextfuncsupervisor}</span>
            </Row>{' '}
            <Row>
              <span>Coordinator</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.contactSupervisor}</span>
            </Row>
            {/* <Row>
              <span>Location</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.location}</span>
            </Row> */}
            <Row>
              <span>CSN Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.csnCode}</span>
            </Row>
            <Row>
              <span>PS Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata?.psNumber}</span>
            </Row>
            <Row>
              <span>Office Tel.</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.officeTel}</span>
            </Row>
            <Row>
              <span>Transport User</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.transportUser}</span>
            </Row>{' '}
            {/* <Row>
              <span>Cadre At Joining</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.cadreAtJoining}</span>
            </Row> */}
            <Row>
              <span>Cadre At Joining</span>
              <span className='span2'>:</span>
              <span className='span3'>{CadreAtJoiningDescription}</span>
            </Row>
            <Row>
              <span>With Company</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.withCompany}</span>
            </Row>{' '}
            <Row>
              <span>Department Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.departmentCode}</span>
            </Row>{' '}
            {/* <Row>
              <span>Current Cadre</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.currentCadre}</span>
            </Row> */}
            <Row>
              <span>Current Cadre</span>
              <span className='span2'>:</span>
              <span className='span3'>{currentCadreDescription}</span>
            </Row>
            <Row>
              <span>Date Of Joining</span>
              <span class='span2'>:</span>
              <span class='span3'>{doj}</span>
            </Row>
            <Row>
              <span>Designation</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.designation}</span>
            </Row>
            <Row>
              <span>Category Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.categoryCode}</span>
            </Row>
            <Row>
              <span>Grade/Training Year</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.gradeYear}</span>
            </Row>
            <Row>
              <span>Date Of Starting Probation</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.dateofStartingProbation
                  ? jobdata.dateofStartingProbation.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Last Date Of Employement</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.lastDateOfEmployement
                  ? jobdata.lastDateOfEmployement.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Date Of Confirmation</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.dateofConfirmation
                  ? jobdata.dateofConfirmation.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Shift Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.shiftCode}</span>
            </Row>
            <Row>
              <span>Date Of Retirement</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.dateofRetiremenet
                  ? jobdata.dateofRetiremenet.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Locker No.</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.lockerNo}</span>
            </Row>
            <Row>
              <span>Sport Group</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.sportGroup}</span>
            </Row>
            <Row>
              <span>Direct Workmen</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.directWorkmen}</span>
            </Row>
            <Row>
              <span>Last Promotion</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.lastPromotion
                  ? jobdata.lastPromotion.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Safety Shoes Size</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.shoeSize}</span>
            </Row>
            <Row>
              <span>Blood Group</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.bloodGroup}</span>
            </Row>
            <Row>
              <span>Bolier Suit Size</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.boilerSuitSize}</span>
            </Row>
            <Row>
              <span>Transfer From Date</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.transferFromDate
                  ? jobdata.transferFromDate.format('DD-MM-YYYY')
                  : ''}
              </span>{' '}
            </Row>
            <Row>
              <span>Transfer To Date</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.transferToDate
                  ? jobdata.transferToDate.format('DD-MM-YYYY')
                  : ''}
              </span>{' '}
            </Row>
            <Row>
              <span>Experience</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.experience}</span>
            </Row>
            <Row>
              <span>Year Of Passing</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.yearOfPassing}</span>
            </Row>
            <Row>
              <span>Educational Qualification</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.academicQualification}</span>
            </Row>
            {/* <Row>
              <span>Educational Qualification</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.academicQualification}</span>
            </Row> */}
            {/* <Row>
              <span>Support Document</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.supportDocument}</span>
            </Row> */}
            <Row>
              <span>Current status</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.currentStatus}</span>
            </Row>
            <Row>
              <span>Appointment Ref. No.</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.appoinmentRefNo}</span>
            </Row>
            <Row>
              <span>Previous PS Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata?.psNo}</span>
            </Row>
            <Row>
              <span>Area(LEMF/East Shops/CS)</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata?.area}</span>
            </Row>
            <Row>
              <span>Appointment Ref. Date.</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.appointmentRefDate
                  ? jobdata.appointmentRefDate.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>TRT Location(1)</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.trtLocation1}</span>
            </Row>{' '}
            <Row>
              <span>TRT Location(2)</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.trtLocation2}</span>
            </Row>{' '}
            <Row>
              <span>Remark</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.remark}</span>
            </Row>{' '}
            <Row>
              <span>Bus Route Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.busRouteCode}</span>
            </Row>{' '}
            <Row>
              <span>Event</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.event}</span>
            </Row>{' '}
            <Row>
              <span>Joining Route</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.joiningRoute}</span>
            </Row>{' '}
            <Row>
              <span>Committee/Post</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.committee}</span>
            </Row>{' '}
            <Row>
              <span>Achievement</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.achievement}</span>
            </Row>
            <Row>
              <span>Primary Job Skill</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.primaryJobSkill}</span>
            </Row>
            {/* <Row>
              <span>Long Service Award 15 Years Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.longServiceAward15
                  ? jobdata.longServiceAward15.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>{' '}
            <Row>
              <span>Date Of Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.dateOfEvent15
                  ? jobdata.dateOfEvent15.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Long Service Award 20 Years Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.longServiceAward20
                  ? jobdata.longServiceAward20.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Date Of Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.dateOfEvent20
                  ? jobdata.dateOfEvent20.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Long Service Award 25 Years Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.longServiceAward25
                  ? jobdata.longServiceAward25.format('DD-MM-YYYY')
                  : ''}
              </span>{' '}
            </Row>
            <Row>
              <span>Date Of Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.dateOfEvent25
                  ? jobdata.dateOfEvent25.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Long Service Award 30 Years Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.longServiceAward30
                  ? jobdata.longServiceAward30.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Date Of Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.dateOfEvent30
                  ? jobdata.dateOfEvent30.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Long Service Award 35 Years Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.longServiceAward35
                  ? jobdata.longServiceAward35.format('DD-MM-YYYY')
                  : ''}
              </span>{' '}
            </Row>
            <Row>
              <span>Date Of Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.dateOfEvent35
                  ? jobdata.dateOfEvent35.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row> */}
          </Col>
        </Row>

        <Divider />
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button
            onClick={() => onPrev(formRef.current?.getFieldsValue())}
            type='primary'>
            Prev
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Summarygeneration;

// const data =[{"name":"test1"},{"name":"test2"}];
// return (
//   <div>
//   {data.map(function(d, idx){
//      return (<li key={idx}>{d.name}</li>)
//    })}
//   </div>
// );
// }
