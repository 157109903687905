import React, { useEffect } from 'react';
import {
  Button,
  Collapse,
  Form,
  Popconfirm,
  Space,
  Typography,
  notification,
} from 'antd';
import {
  WarningOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import OrganisationLocationPanel from './OrganisationLocationPanel';
import { useNavigate } from 'react-router-dom';
import { Email } from '../../services/auth';
import useAuth from '../../hooks/useAuth';
import { getDetails } from '../../services/auth';

const { Panel } = Collapse;

export default function OrganisationLocationForm({
  initialValues,
  onPrev,
  onNext,
  orgInfoData,
  orgAdminData,
}) {
  const formRef = React.useRef();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [activeKeys, setActiveKeys] = React.useState([]);
  const [data1, setData1] = React.useState(orgInfoData);
  const [data, setData] = React.useState(initialValues);
  const [dataFetched, setDataFetched] = React.useState(false);
  const [permissions, setPermissions] = React.useState([]);
  const [childData, setChildData] = React.useState('');
  const [approvers, setApprovers] = React.useState([]);
  const [exceptions, setExceptions] = React.useState({});
  const [checkedList, setCheckedList] = React.useState({});

  const [selectedPanel, setSelectedPanelId] = React.useState('');
  const { userConfig } = useAuth();
  const roleName = userConfig?.roleName;

  //

  //
  const orgId = userConfig.orgId;

  useEffect(() => {
    //
    let temp = {};
    initialValues.locations.forEach((location, index) => {
      if (location.hasOwnProperty('exceptions') && location.exceptions) {
        temp = {
          ...temp,
          [index]: location.exceptions,
        };
      }
    });
    let temp1 = {};

    initialValues.locations.forEach((location, index) => {
      if (location.hasOwnProperty('approvers') && location.approvers) {
        temp1 = {
          ...temp1,
          [index]: location.approvers,
        };
      }
    });
    //
    setExceptions(temp);
    setCheckedList(temp1);
  }, [initialValues]);

  const receiveDataFromChild = (data) => {
    //
    setChildData(data);
    //
  };

  const approverListFromChild = (data) => {
    //
    setApprovers(data);
    //
  };

  // const panelId = (data) =>{
  //   setSelectedPanelId(data)
  //
  // }

  const onFinish = (values) => {
    values?.locations?.forEach((item) => {
      item?.users?.forEach((item) => {
        let _id = item.roleName;
        const selectedPermission = permissions.find((item) => {
          return item._id === _id;
        });
        //
        item.roleName = selectedPermission?.selectedRoleType;
        item.permissions = selectedPermission?.permissions;
      });
    });

    values.locations.forEach((location, index) => {
      location.exceptions = exceptions.hasOwnProperty(index)
        ? exceptions[index]
        : [];
      location.approvers = checkedList.hasOwnProperty(index)
        ? checkedList[index]
        : [];
      // location.approvers = approvers[index]
    });
    //Sachin Code
    values.locations.forEach((location, index) => {
      location.documents = {
        centralIR: location.centralIRDocs,
        regularIR: location.regularIRDocs,
        contractAgency: location.contractAgencyDocs,
      };
    });
    //------

    // Add childData to the exceptions field of the last location object
    //  const lastLocationIndex = values.locations.length - 1;
    //  if (lastLocationIndex >= 0) {
    //      values.locations[lastLocationIndex].exceptions = childData;
    //  }

    // Add childData to the exceptions field of the last location object
    //const lastLocationIndex = values.locations.length - 1;
    //  if (lastLocationIndex >= 0) {
    //      values.locations[lastLocationIndex].approvers = approvers;

    //  }

    //
    //

    //     Email({
    //       workmenName:orgAdminData[0].firstName+" "+orgAdminData[0].lastName,
    //       userid:orgAdminData[0].userid,
    //       templateId:"CREATE_SUPERVISOR",
    //       // text: "Hi, Welcome to the OLO application. Please find below the following credentials for your login:Link:http://localhost:3000/api/user/login UserName:{{userid}} OrganizationId:{{orgId}} Password:{{password}} Kindly note that the password is system generated and can be used for the first-time login only. You should change your password immediately upon gaining access to the application by clicking on ‘Change password’.",
    //       password:orgAdminData[0].password,
    //       orgId:data1.id,
    //       role:orgAdminData[0].role
    // })
    //       .then((res) => {
    //         // notification.success({ message: res.data.message });
    //       })
    //       .catch((err) => {
    //         notification.error({ message: err.response.data.message });
    //       });

    onNext(values);
    // navigate('/home/admin');
  };

  React.useEffect(() => {
    //
    if (!dataFetched) {
      fetchData();
    }
  }, [dataFetched]); // The empty dependency array ensures that the effect runs only once on mount

  const fetchData = () => {
    getDetails(orgId)
      .then((response) => {
        const filteredData = response?.data
          .map((item) => {
            // Check if the selectedRole is "Approver"
            if (
              item.selectedRole === 'Approver' ||
              item.selectedRole === 'Shop' ||
              item.selectedRole === 'IR'
            ) {
              // If it is "Approver," return the modified item or just the property you need
              return {
                createdAt: item.createdAt,
                orgId: item.orgId,
                permissions: item.permissions,
                selectedRole: item.selectedRole,
                selectedRoleType: item.selectedRoleType,
                _id: item._id,
                // Add other properties as needed
              };
            }

            // If selectedRole is not "Approver," return null or an empty object
            return null;
          })
          .filter((filteredItem) => filteredItem !== null); // Remove null entries from the result

        //

        setPermissions(filteredData);
        setDataFetched(true);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };
  //

  const getErrorIndex = (errors, name) => {
    let errorIndexes = new Set();

    if (errors) {
      errors
        .filter((e) => e.errors.length > 0 && e.name.includes(name))
        .forEach((e) => errorIndexes.add(e.name[1]));
    }

    return [...errorIndexes];
  };
  //
  //const currentValues = formRef.current?.getFieldsValue();
  //

  return (
    <Space
      direction='vertical'
      style={{ margin: '22px 10px', display: 'flex' }}>
      <Form
        initialValues={initialValues}
        ref={formRef}
        onFinish={onFinish}
        layout='vertical'>
        <Form.List name='locations'>
          {(fields, actions) => {
            //
            const errorIndexes = getErrorIndex(
              formRef.current?.getFieldsError(),
              'locations'
            );

            const isAdmin =
              userConfig && userConfig.roleName && userConfig.role === 'admin';

            return (
              <Space direction='vertical' style={{ display: 'flex' }}>
                <Form.Item>
                  <Button
                    type='dashed'
                    disabled={userConfig.roleName}
                    onClick={() => {
                      setActiveKeys((a) => [...a, fields.length]);
                      actions.add({
                        name: '',
                        aadharCheck: orgInfoData.aadharCheck,
                        secureCheck: orgInfoData.secureCheck,
                        exceptions: [],
                        users: [],
                        // documents: [],

                        documents: {},
                        approvers: [],
                      });
                    }}
                    block
                    icon={<PlusOutlined />}>
                    Add Locations
                  </Button>
                </Form.Item>

                {fields.length > 0 && (
                  <Collapse
                    activeKey={activeKeys}
                    onChange={setActiveKeys}
                    expandIconPosition='end'>
                    {fields.map((field, index) => {
                      const locationName = formRef.current?.getFieldValue([
                        'locations',
                        field.name,
                        'id',
                      ]);
                      const locationName1 = formRef.current?.getFieldValue([
                        'locations',
                        field.name,
                        'name',
                      ]);

                      // Check if the user is an admin
                      const isAdmin =
                        userConfig &&
                        userConfig.roleName &&
                        userConfig.role === 'admin';

                      // Determine if the panel should be enabled based on isAdmin
                      const isEnabled =
                        !isAdmin ||
                        (userConfig &&
                          userConfig.location &&
                          userConfig.location.includes(locationName));

                      // Define the color style based on whether the panel is enabled or disabled
                      const textColorStyle = isEnabled
                        ? {}
                        : { color: '#aaaaaa' }; // Lighter shade color when disabled

                      return (
                        <Panel
                          header={
                            <Form.Item shouldUpdate noStyle>
                              {() => (
                                <Typography.Text strong style={textColorStyle}>
                                  {/* {!isAdmin && index === 0 */}
                                  {index === 0
                                    ? `Ho Location (${locationName1})`
                                    : `Location ${index === 0 ? '#' : ''}${
                                        index + 1
                                      } ${locationName1}`}
                                </Typography.Text>
                              )}
                            </Form.Item>
                          }
                          key={index}
                          forceRender
                          disabled={!isEnabled} // Disable the panel if user is admin and location not included
                          extra={
                            <Space>
                              {isEnabled && (
                                <Popconfirm
                                  title='Delete'
                                  onConfirm={(e) => {
                                    e.stopPropagation();
                                    actions.remove(index);
                                  }}
                                  onCancel={(e) => e.stopPropagation()}>
                                  <Button
                                    shape='circle'
                                    onClick={(e) => e.stopPropagation()}
                                    icon={<DeleteOutlined />}
                                    disabled={
                                      userConfig?.location ||
                                      (index === 0 &&
                                        initialValues?.locations[index]
                                          ?.approvalLevel === 0)
                                    }
                                  />
                                </Popconfirm>
                              )}
                              {errorIndexes.includes(index) && (
                                <WarningOutlined style={{ color: 'red' }} />
                              )}
                            </Space>
                          }>
                          <OrganisationLocationPanel
                            field={field}
                            index={index}
                            formRef={formRef}
                            orgInfoData={orgInfoData}
                            sendDataToParent={receiveDataFromChild}
                            approversList={approverListFromChild}
                            LocationsData={initialValues}
                            tempId={fields[index].key}
                            exceptions={exceptions}
                            setExceptions={setExceptions}
                            checkedList={checkedList}
                            setCheckedList={setCheckedList}
                            disabled={
                              index === 0 &&
                              initialValues.locations[index]?.approvalLevel ===
                                0
                            }
                          />
                        </Panel>
                      );
                    })}
                  </Collapse>
                )}
              </Space>
            );
          }}
        </Form.List>

        <Space
          style={{ width: '100%', justifyContent: 'end', marginTop: '24px' }}>
          <Button onClick={() => onPrev(data)}>Prev</Button>
          <Form.Item noStyle>
            <Button type='primary' htmlType='submit'>
              Next
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Space>
  );
}
